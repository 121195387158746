<template>
	<div class="biaoqingbtn" >
		<img src="../assets/img/biaoqin2.png" style="cursor: pointer;width: 25px;height: 25px;" alt="" @click.stop="toggle"  v-if="white">
		<img src="../assets/img/biaoqin.png" style="cursor: pointer;width: 25px;height: 25px;" alt="" @click.stop="toggle" v-else>
		
		<div class="biaoqingbox" v-show="show" @click.stop="" v-loading="loading&&show">
			<div class="bqlist">
				<div class="item" v-for="item in biaoqing" :key="item.name" @click="select_bq(item.name)">
					<img :src="item.icon" :alt="item.name" :title="item.name">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				loading:true,
				show:false,
				biaoqing:[],
			};
		},
		props:{
			inputId:{
				type:String,
				default:''
			},
			white:{
				type:Boolean,
				default:false
			}
		},
		
		created() {
			let that=this;
			document.onclick = function() {				
				that.show=false;
			};
			
		},
		methods:{
			async toggle(){
				this.show=!this.show;
				if(this.biaoqing.length==0){
					this.getDatas();
				}
			},
			async getDatas(){
				let res=await this.$request.get('/api/sysdict/getEmote');
				this.biaoqing=res.data.biaoqing;
				this.loading=false;
			},
			getval(){
				const myField = document.getElementById(this.inputId);
				let val=myField.value;
				// for (var i = 0; i < this.biaoqing.length; i++) {
				// 	let n='<'+this.biaoqing[i].name+'>';
				// 	let reg=new RegExp(n,'g');
				// 	val=val.replace(reg,'<img src="'+this.biaoqing[i].icon+'">');
				// }
				return(val);
				//this.$emit('change',val);
			},
			select_bq(name){
				this.show=false;
				// let value='<'+name+'>';				
				let value=name;				
				const myField = document.getElementById(this.inputId);
				//IE 浏览器
				if (document.selection) {
				  myField.focus();
				  sel = document.selection.createRange();
				  sel.text = value;
				  sel.select();
				}
							
				//FireFox、Chrome等
				else if (myField.selectionStart || myField.selectionStart == '0') {
				  var startPos = myField.selectionStart;
				  var endPos = myField.selectionEnd;
							
				  // 保存滚动条
				  var restoreTop = myField.scrollTop;
				  myField.value = myField.value.substring(0, startPos) + value + myField.value.substring(endPos, myField.value.length);
				  
				  if (restoreTop > 0) {
				   myField.scrollTop = restoreTop;
				  }
				  
				  myField.focus();
				  myField.selectionStart = startPos + value.length;
				  myField.selectionEnd = startPos + value.length;
				} else {
				  myField.value += value;
				  myField.focus();
				}
				const event = document.createEvent('HTMLEvents');
				event.initEvent('input', false, true);
				
				myField.dispatchEvent(event); // 手动触发输入框的input事件
				
				//console.log(myField.value);
			},
		}
		
	};
</script>

<style lang="less">
	.biaoqingbtn{position: relative;}
	.biaoqingbox{position: absolute;left: 0;bottom: 100%;z-index: 9;min-height: 200px;
		.bqlist{display: flex;flex-wrap: wrap;background-color: #fff;border-radius: 5px;padding: 15px;width: 400px;box-shadow: 0 0 10px rgba(#000,.1);max-height: 240px;overflow-y: auto;
			.item{width: 50px;height: 50px;display: flex;align-items: center;justify-content: center;
				img{width: 30px;height: 30px;}
				&:hover{background-color: #f8f8f8;border-radius: 5px;cursor: pointer;
					img{width: 35px;height: 35px;}
				}
			}
		}
	}
</style>