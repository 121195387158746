<template>
	<div class="reply">
		<el-dialog :title="$t('home.HF')" :visible.sync="show" width="30%"  @close="close" >
			<span><!-- 回复 -->{{$t('home.HF')}}：@{{name}} </span>
			<el-input v-model="content" id="plbox2" class="input_box" :placeholder="$t('QSR')"></el-input>
			<div class="input_add">
				<!-- <img src="../../assets/img/biaoqin.png" class="biaoqin" alt=""> -->
				<biaoqing inputId="plbox2" ref="biaoqing"></biaoqing>
				<el-button type="primary" @click="huifu"><!-- 回复 -->{{$t('home.HF')}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import biaoqing from '../../components/biaoqing.vue';
	export default {
		components:{
			
			biaoqing
		},
		data() {
			return {
				show:false,
				content:''
			};
		},
		props:{
			name:{type:String,default:''},
			id:{type:Number,default:0},
			trend_id:{type:Number,default:0},
		},
		methods: {
			open(){
				this.show=true;
				this.content='';
				this.$emit('open');
			},
			close(){
				this.show=false;
				this.$emit('close');
				//console.log('close');
			},
			//回复评论
			async huifu(){
				let val=this.$refs.biaoqing.getval();
				console.log(val);
				//return;
				let res=await this.$request.post('/api/trend/comment',{trend_id:this.trend_id,reply_comment_id:this.id,content:val});
				this.$message({
					message:res.message,
					type:'success'
				});
				this.$emit('send');
				this.close();
			},
		}
	};
</script>

<style lang="less" scoped>
	.reply {
		.el-dialog{
			background-color: #F3F1EA;
		}
		
		.input_box {
			width: 100%;
			height: 38px;
			background: #FFFFFF;
			border-radius: 9px;
			margin: 15px 0;
			box-sizing: border-box;
			font-size: 14px;
		}

		.input_add {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 42px;

			.biaoqin {
				width: 25px;
				height: 25px;
			}

			.el-button {
				background-color: #567BB6;
				border: none;
			}
		}
	}
</style>
